<template>
  <div class="wrap">
    <div class="syj_Betinfo-shadow"></div>
    <div class="syj_Betinfo">
      <div class="betClose">
        <img
            @click="IsClosebet"
            src="../assets/images/betClose.png"
        />
      </div>
      <div class="syj_Betinfo-tab">
        <div
            class="tabbet"
            @click="tab1"
            :class="activeClasstab1 == 0 ? 'actived' : ''"
        >
          <label><span></span></label><span>我命由我</span>
        </div>
        <div
            class="tabbet"
            @click="tab2"
            :class="activeClasstab2 == 0 ? 'actived' : ''"
        >
          <label><span></span></label><span>我命由天</span>
        </div>
      </div>
      
      <div
          class="syj_betcontentinput"
          :class="activeClasstab1 == 0 ? 'actived' : ''"
      >
        <div
            class="syj_betnum"
            v-for="(itembetline, index) in BetListInputline"
            :key="index"
        >
          <label> {{ itembetline }} </label>
          <div class="syj_betnum-input">
						<span
                v-for="(site, key) in lotteryInfo.num"
                :key="key"
            >
							<input
                  :class="`border-input_${index}`"
                  :name="`number[${index}][name]`"
                  :key="site"
                  type="text"
                  autocomplete="off"
                  v-model="numInfo[index][key]"
                  @input="inputBetnum($event, index, key)"
                  @keyup="nextFocus($event, index, key)"
                  @mouseup="mouseUp(index, key)"
              />
						</span>
          </div>
        </div>
      </div>
      
      <div
          class="syj_betcontentinput"
          :class="activeClasstab2 == 0 ? 'actived' : ''"
      >
        <div class="syj_sky">
          <label
              @click="allrodmon"
              :class="allrodmonClose == 0 ? 'actived' : ''"
          >
            {{ skyall }}
          </label>
          <label
              @click="qujianshow"
              :class="rodmonClose == 0 ? 'actived' : ''"
          >
            {{ skyqujian }}
          </label>
        </div>
        
        <div
            class="syj_betting_group"
            v-if="!this.editFlag"
        >
          投注数量
          <label
              v-for="(item,index) in bettingGroup"
              :key="index"
              @click="bettingGroupChange(item)"
          >
            
            <input
                type="radio"
                name="betting_group"
                :key="index"
                v-model="radioVal"
                :value="item"
            /><span></span>{{ item }}注
          </label>
        </div>
        
        <div
            class="sky-input"
            v-if="qujianCLose"
        >
          <input
              type="number"
              @input="inputStr($event)"
          /> <i>-</i>
          <input
              type="number"
              @input="inputEnd($event)"
          />
          <div
              class="btn"
              @click="qujianrodmon"
          >生成
          </div>
        </div>
        <div class="sky">
          <div
              v-for="(item, index) in BetListInputrandom"
              :key="index"
              class="syj_betnum"
          >
            <div class="syj_betnum-input">
							<span
                  class="numrandom"
                  v-for="(item2, index2) in item"
                  :key="index2"
              >
								<input
                    :name="`number[${index}][name]`"
                    :key="index2"
                    type="text"
                    v-model="item[index2]"
                    @input="inputBetnumRodmon($event, index, index2)"
                />
							</span>
            </div>
            <div
                class="cursorPointer delete"
                @click="del(index)"
            >x
            </div>
          </div>
        </div>
      </div>
      <div class="notice">
        <div style="font-size: 12px;text-align: center;">合理竞猜，财路打开！一注包赢，多注躺平！</div>
        <div style="font-size: 12px;margin-top: 10px;text-align: center;">竞猜时间段:
          {{ $_parent.currentActivityInfo.activity_round.ing_start_time }} ~
          {{ $_parent.currentActivityInfo.activity_round.ing_end_time }}
        </div>
      </div>
      <!--      <div class="notice">测试轮5元一注，买一赠九，每人限购一次</div>-->
      <!-- 投注 弹出层相关内容 end -->
      <!-- selectBox 下单承诺 start -->
      <div class="syj_contentselect">
        下单承诺
        <div
            class="selectBox"
            style="width: 278px"
        >
          <div
              class="selectBox_show"
              v-on:click.stop="selectarrowDown"
          >
            <p>{{ unitName }}</p>
            <i></i>
          </div>
          <div
              class="selectBox_list"
              v-show="isShowSelect"
          >
            <div
                class="selectBox_listLi"
                v-for="(item, index) in selectdataList"
                :key="index"
                @click="select(item, index)"
            >
              {{ item.title }}
            </div>
          </div>
        </div>
      </div>
      <!-- selectBox 下单承诺 end -->
      <div
          class="btnselect"
          @click="addToBetList"
      >
        确定
      </div>
    </div>
    <PayQrCode-info @IsClosebetp="IsClosebet" @IsCloseqrcodelist="IsCloseQrcode" v-if="onOpenQrcode"
                    :parentQrcodeData="QrcodeData"></PayQrCode-info>
    <div v-if="paymentisLoading" class="bill_loading">
      <div class="bill_loading-spinner"></div>
    </div>
  </div>
</template>
<script>
import maiyuanRequest from "../units/maiyaunRuquest.js";
import {memberBetting, editOrder, creat_qrcode} from "../units/api.js";
import {getActivity} from "../units/activity.js";
import PayQrCode from "@/components/PayQrCode";

export default {
  name: "",
  inject: ['$_parent'],
  data() {
    return {
      paymentisLoading: false,//支付请求加载动画
      rodmonClose: -1,
      allrodmonClose: 0,
      qujianCLose: false,
      Betnum: 1,
      BetListInput: [], //投注list
      BetListInputline: [], //投注行数
      skyall: "全都由天",
      skyqujian: "给个区间",
      Betnumandom: 1,
      BetListNum: "第",
      BetListNumend: "注",
      QrcodeData: {},//付款码数据
      unitName: "假如我中奖了......",
      isShowSelect: false,
      selectdataList: [], //suijish
      BetListInputrandom: [],
      BetListInputtext: [],
      activeClasstab1: 0,
      activeClasstab2: -1,
      strNum: null,
      endNum: null,
      numInfo: [],
      lotteryInfo: {
        line: 10, //生成的行数
        num: 7, //位数
      },
      lotterySubmitType: true, //区分投注类型
      lotteryEditType: false, //是否修改类型
      unitId: null, //下单承诺ID,
      bettingGroup: [10, 20, 50, 100],
      radioVal: 10,
      onOpenQrcode: this.onOpenQrcode,//支付弹窗开关
    };
  },
  props: ["parentBetListInputline", "orderId", "editFlag"],
  mounted() {
    //行数初始化
    for (let i = 1; i <= 10; i++) {
      this.BetListInputline.push(
          this.BetListNum + "" + i.toString() + "" + this.BetListNumend
      );
    }
    this.homeOderprimose();
    //是否父类传值
    if (this.editFlag == true && this.parentBetListInputline) {
      this.lotteryEditType = true; //设置为修改类型
      this.numInfo = this.parentBetListInputline;
      let sheng = this.lotteryInfo.line - this.numInfo.length;
      if (sheng > 0) {
        let num = new Array(sheng);
        for (var i = 0; i < num.length; i++) {
          num[i] = new Array(this.lotteryInfo.num);
        }
        this.numInfo = this.numInfo.concat(num);
      }
      console.log("this.lotteryInfo.line", this.lotteryInfo.line);
    } else {
      this.lotteryInit();
    }
    if (!this.lotteryEditType) {
      this.tab2();//默认我命由天
    }
    // this.homeBetPop();
  },
  components: {
    "PayQrCode-info": PayQrCode,
  },
  methods: {
    IsCloseQrcode() {
      this.onOpenQrcode = false;
    },
    inputBetnumRodmon(event, index, key) {
      this.BetListInputrandom[index][key] = event.currentTarget.value.match(/\d{1}?/)
          ? parseInt(event.currentTarget.value.match(/\d{1}?/)[0])
          : null;
    },
    async bettingGroupChange(item) {
      this.radioVal = item
      let settingGroupStrNum = this.strNum ? this.strNum : ''
      let settingGroupEndNum = this.endNum ? this.endNum : ''
      await this.getRandomNumber(settingGroupStrNum, settingGroupEndNum, item).then((res) => {
        let response = res.data;
        this.BetListInputrandom = [];
        for (let i = 0; i < response.data.length; i++) {
          this.BetListInputrandom[i] = response.data[i];
        }
        this.BetListInputrandom = JSON.parse(JSON.stringify(this.BetListInputrandom))
      });
    },
    ValidityOrder(num) {
      console.log("num", num);
      let flag = [];
      for (let i = 0; i < num.length; i++) {
        flag[i] = 0;
        for (let j = 0; j < num[i].length; j++) {
          if (num[i][j] != null) {
            flag[i]++;
          }
        }
      }
      console.log("num", flag);
      for (let k = 0; k < flag.length; k++) {
        if (flag[k] > 0 && flag[k] < 7) {
          return false;
        }
      }
      let nums = 0;
      for (let k = 0; k < flag.length; k++) {
        if (flag[k] > 0 && flag[k] == 7) {
          nums++;
        }
      }
      return nums;
    },
    del(index) {
      this.BetListInputrandom.splice(index, 1);
    },
    lotteryInit() {
      let num = new Array(this.lotteryInfo.line);
      for (var i = 0; i < num.length; i++) {
        num[i] = new Array(this.lotteryInfo.num);
      }
      this.numInfo = num;
      console.log("this.numInfo", this.numInfo);
    },
    IsClosebet() {
      this.$emit("IsClosebetlist");
    },
    qujianshow() {
      (this.rodmonClose = 0), (this.allrodmonClose = -1);
      this.qujianCLose = true;
      // this.lotterySubmitType = false;
    },
    selectarrowDown() {
      this.isShowSelect = !this.isShowSelect;
      // this.selectdataList.push({
      //   selectdataList: this.selectdataList,
      // });
    },
    select(item) {
      this.isShowSelect = false;
      this.unitId = item.id;
      this.unitName = item.title;
    },
    addToBetList() {
      this.BetListInput.push(this.Betnum);
      this.BetListInput.push(this.Betnumandom);
      
      this.homeBetPop();
      console.log(this.BetListInput, this.lotterySubmitType);
    },
    mouseUp(index, key) {
      if (this.numInfo[index][key] != null) {
        var dom = document.getElementsByClassName("border-input_" + index),
            currInput = dom[key];
        currInput.setSelectionRange(0, -1);
      }
    },
    
    inputBetnum(event, index, key) {
      this.handleBatchInput(event, index, key)
      this.numInfo[index][key] = event.currentTarget.value.match(/\d{1}?/)
          ? parseInt(event.currentTarget.value.match(/\d{1}?/)[0])
          : null;
      this.Betnum = event.currentTarget.value;
      this.site = event.currentTarget.value;
    },
    
    handleBatchInput(event, index) {
      try {
        const regex = /^(?:\d{7} ?)*\d{7}$/
        if (regex.test(event.data)) {
          let list = event.data.split(' ')
          
          const remainingSpace = this.numInfo.length - index;
          if (list.length > remainingSpace) {
            list = list.slice(0, remainingSpace)
          }
          list = list.map(item => {
            return item.split('').map(i => Number(i))
          })
          this.$nextTick(() => {
            this.numInfo.splice(index, list.length, ...list);
          })
          
          return
        }
      } catch (e) {
        console.log(e, 'err')
      }
    },
    
    nextFocus(el, index, key) {
      console.log(el.keyCode);
      if (el.keyCode == 18) {
        return;
      }
      var dom = document.getElementsByClassName("border-input_" + index),
          currInput = dom[key],
          nextInput = dom[key + 1],
          lastInput = dom[key - 1];
      // console.log("按键" + el.keyCode, "行数" + index + 1, "位置" + key);
      /*这里的keyCode 根据不同的平台或许不同,安卓就是不是8*/
      
      if (el.keyCode == 9) {
        if (typeof lastInput != "undefined" && lastInput.value.length == 0) {
          lastInput.focus();
          return;
        }
        if (typeof nextInput != "undefined" && nextInput.value.length > 0) {
          currInput.focus();
          return;
        }
        if (this.numInfo[index][key] != null) {
          currInput.focus();
        }
      } else {
        if (currInput.value.length == 0) {
          currInput.focus();
          return;
        }
        if (key < this.numInfo[index].length - 1) {
          if (nextInput.value != null) {
            nextInput.setSelectionRange(0, -1);
          } else {
            nextInput.blur();
          }
          nextInput.focus();
        } else {
          currInput.blur();
        }
      }
    },
    inputStr(event) {
      this.strNum = event.currentTarget.value;
      console.log("strNum", this.strNum);
    },
    inputEnd(event) {
      this.endNum = event.currentTarget.value;
    },
    tab1: function () {
      this.activeClasstab1 = 0;
      this.activeClasstab2 = -1;
      this.lotterySubmitType = true;
    },
    
    tab2: async function () {
      this.activeClasstab1 = -1;
      this.activeClasstab2 = 0;
      // this.make();
      await this.getRandomNumber().then((res) => {
        let response = res.data;
        this.BetListInputrandom = [];
        for (let i = 0; i < response.data.length; i++) {
          this.BetListInputrandom[i] = response.data[i];
        }
        this.BetListInputrandom = JSON.parse(JSON.stringify(this.BetListInputrandom))
      });
      this.lotterySubmitType = false;
    },
    async allrodmon() {
      // this.make();
      let settingNum = this.radioVal
      await this.getRandomNumber('', '', settingNum).then((res) => {
        let response = res.data;
        this.BetListInputrandom = [];
        for (let i = 0; i < response.data.length; i++) {
          this.BetListInputrandom[i] = response.data[i];
        }
        this.BetListInputrandom = JSON.parse(JSON.stringify(this.BetListInputrandom))
      });
      this.allrodmonClose = 0;
      this.qujianCLose = false;
      this.rodmonClose = -1;
      // this.lotterySubmitType = false;
    },
    async qujianrodmon() {
      // console.log(this.strNum,this.endNum)
      // let that = this;
      // let strNum = that.strNum ? that.strNum : 59999;
      // let endNum = that.endNum ? that.endNum : 130000;
      // //  this.make(strNum,endNum);
      // if (strNum >= 59999 && endNum <= 130000) {
      //   return;
      // } else {
      //   that.$toast.show("区间值最少59999最大值130000");
      // }
      let settingNum = this.radioVal
      await this.getRandomNumber(this.strNum, this.endNum, settingNum).then((res) => {
        let response = res.data;
        if (response.status == 400) {
          this.$alert(response.msg, "", "", {
            customClass: "customTotasClass",
            customContainerClass: "containerClass",
          });
          return;
        }
        this.BetListInputrandom = [];
        for (let i = 0; i < response.data.length; i++) {
          this.BetListInputrandom[i] = response.data[i];
        }
        this.BetListInputrandom = JSON.parse(JSON.stringify(this.BetListInputrandom))
      });
      // that.lotterySubmitType = false;
    },
    getRandomNumber(strNum, endNum, num = 10) {
      let params = {digit: 6, num}
      if (strNum && endNum) {
        params = {digit: 6, section: strNum + "--" + endNum, num}
      }
      return new Promise((resolve, reject) => {
        maiyuanRequest({
          method: "get",
          url: "random_number", //下单承诺的接口
          params,
        })
            .then((res) => {
              resolve(res);
            })
            .catch((err) => {
              reject(err);
            });
      });
    },
    make(num1, num2) {
      let that = this;
      let s = this.lotteryInfo.line; //生成的数组数量
      this.BetListInputrandom = [];
      for (let i = 0; i < s; i++) {
        this.BetListInputrandom[i] = that.shengcheng(num1, num2);
      }
    },
    shengcheng(num1 = 59999, num2 = 130000) {
      console.log(typeof num2, typeof num1);
      // console.log(Math.floor((num2-num1)*Math.random()+num1)+"," + Math.floor(Math.random() * 10) + "")
      let num =
          Math.floor(
              (parseInt(num2) - parseInt(num1)) * Math.random() + parseInt(num1)
          ) +
          "" +
          Math.floor(Math.random() * 10);
      console.log("jennynum", num);
      return this.prefixZero(num, 7).split("");
    },
    prefixZero(num, n) {
      return (Array(n).join(0) + num).slice(-n);
    },
    homeBetPop() {
      let that = this;
      let nums = null;
      let type = this.lotterySubmitType ? 1 : 2;
      let promiseId = this.unitId ? this.unitId : null;
      if (this.lotterySubmitType) {
        nums = that.numInfo;
      } else {
        nums = that.BetListInputrandom;
      }
      let validateData = this.ValidityOrder(nums);
      if (!validateData) {
        this.$alert("投入票数位数错误", "", "", {
          customClass: "customTotasClass",
          customContainerClass: "containerClass",
        });
      } else {
        if (promiseId == null) {
          that.$toast.show("至少选择一个下单承诺", 10000);
          return true;
        }
        this.$confirm("确认下注数量为" + validateData + "注?", "", "", {
          customContainerClass: "containerClass",
          cancelButtonText: "再想想", confirmButtonText: "OK",
          showLoaderOnConfirm: true,
          
          preConfirm: function () { //功能执行前确认操作，支持function
            
            let jiangjinchi = new Promise(function (resolve) {
              if (that.lotteryEditType == true) {
                let params = {order_id: that.orderId};
                let data = {type: type, promise_id: promiseId, num: nums};
                that.paymentisLoading = true;
                editOrder(params, data).then((res) => {
                  that.paymentisLoading = false;
                  let response = res.data;
                  that.IsClosebet();
                  that.$emit("editData", that.orderId, nums);
                  that.$toast.show(response.msg, 10000);
                  console.log("请立即打印奖券去");
                  resolve()
                });
              } else {
                let activityInfo = getActivity()
                    ? JSON.parse(getActivity())
                    : null;
                let activtiyId = activityInfo.activityId || null;
                let activityRoundId = activityInfo.activityRoundId || null;
                that.paymentisLoading = true;
                memberBetting(
                    {type: type, promise_id: promiseId, num: nums},
                    {activtiy_id: activtiyId, activity_round_id: activityRoundId}
                ).then((res) => {
                  if (res.status == 400) {
                    that.paymentisLoading = false;
                    that.$toast.show(res.msg, 10000);
                    resolve()
                    // return;
                  } else {
                    let response = res.data;
                    that.payment(response.data.order_ids)
                    // that.$toast.show(response.msg, 10000);
                    resolve()
                  }
                });
              }
            })
            console.log("aaaa", jiangjinchi)
          },
          allowOutsideClick: () => !this.IsClosebet()
        }).then((result) => {
          console.log(result)
        }).catch(error => {
          console.log('error2', error)
        });
        
      }
    },
    payment: function (selectedValues) {  //投注成功后直接弹付款
      if (selectedValues.length == 0) {
        this.$toast.show('订单信息错误', 10000);
      } else {
        let order_ids = selectedValues.join(',');
        let activityInfo = getActivity()
            ? JSON.parse(getActivity())
            : null;
        let activtiyId = activityInfo.activityId || null;
        let activityRoundId = activityInfo.activityRoundId || null;
        creat_qrcode(
            {activtiy_id: activtiyId, activity_round_id: activityRoundId, order_id: order_ids}
        ).then((res) => {
          this.paymentisLoading = false;
          if (res.status == 400) {
            this.$toast.show(res.msg, 10000);
            return;
          } else {
            this.QrcodeData = res.data
            this.onOpenQrcode = true
            // this.IsClosebet();
            console.log(this.QrcodeData)
          }
        });
      }
    },
    homeOderprimose() {
      let that = this;
      maiyuanRequest({
        method: "get",
        url: "order_promise", //下单承诺的接口
      }).then((res) => {
        let response = res.data;
        that.selectdataList = response.data;
        console.log("投注test", response.data);
      });
    },
  },
};
</script>
<style lang="less">
.syj_betting_group {
  color: #fff;
  display: flex;
  align-items: center;
  padding: 0px 0 12px;
  
  label {
    position: relative;
    cursor: pointer;
  }
  
  label span {
    width: 10px;
    height: 10px;
    border: 2px solid #e81f3c;
    display: inline-block;
    border-radius: 50%;
    margin: 0 2px 0 4px;
  }
  
  label input {
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
  }
}

.syj_betting_group label input:checked ~ span:after {
  content: "";
  display: block;
  position: absolute;
  width: 14px;
  height: 14px;
  background: #e81f3c;
  border-radius: 100%;
  left: 4px;
  top: 3px;
  transform: scale(0.45);
}

.syj_Betinfo {
  .notice {
    color: #fff;
    text-align: left;
    width: 73%;
    margin-top: 12px;
    line-height: 12px;
    font-size: 14px;
  }
  
  .icon-jiao {
    position: relative;
    width: 20px;
    height: 20px;
    display: inline-block;
    
    &::after {
      position: absolute;
      width: 8px;
      height: 1px;
      content: "";
      background: #ffffff;
      left: 10px;
      bottom: 6px;
      z-index: 1;
      transform: rotate(-45deg);
    }
    
    &::before {
      position: absolute;
      width: 8px;
      height: 1px;
      content: "";
      background: #ffffff;
      left: 10px;
      top: 8px;
      z-index: 1;
      transform: rotate(45deg);
    }
  }
  
  .syj_contentselect {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 73%;
    color: #fff;
    font-weight: 600;
    font-size: 14px;
    letter-spacing: 1px;
    margin-top: 12px;
    
    .selectBox {
      border: 2px solid #fb3123;
      background: #fff;
      
      .selectBox_show {
        text-align: left;
        cursor: pointer;
        position: relative;
        
        p {
          color: red;
          font-size: 12px;
          padding: 10px 0;
          padding-left: 10px;
          margin: 0;
        }
        
        i {
          position: absolute;
          top: 15px;
          right: 10px;
          width: 0;
          height: 0;
          border-width: 8px 6px 0;
          border-style: solid;
          border-color: #fb3123 transparent;
          border-radius: 10px;
          display: inline-block;
        }
      }
    }
    
    .selectBox_list {
      height: 120px;
      color: #2e2e2e;
      margin-bottom: 5px;
      text-align: left;
      overflow: auto;
      font-size: 12px;
      padding-left: 10px;
      line-height: 22px;
      background-color: #ffffff;
      position: absolute;
      width: 268px;
      border: 2px solid #f44336;
      margin-left: -2px;
      border-top: none;
      
      .selectBox_listLi {
        cursor: pointer;
      }
      
      .selectBox_listLi:hover {
        color: #f44336;
      }
    }
  }
  
  .betClose {
    width: auto;
    cursor: pointer;
    text-align: right;
    margin: 10px 8px 3px;
    position: absolute;
    top: 0;
    right: 7px;
  }
  
  .syj_Betinfo-tab {
    width: 370px;
    display: flex;
    justify-content: space-around;
    padding: 5px 0 10px;
    color: #fff;
    margin-top: 40px;
    
    .tabbet {
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      
      label {
        position: relative;
        width: 16px;
        height: 16px;
        border: 2px solid #e81f3c;
        display: block;
        border-radius: 50%;
        margin-right: 10px;
      }
      
      &.actived {
        label {
          span {
            width: 10px;
            height: 10px;
            background: #e81f3c;
            display: inline-block;
            border-radius: 50%;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
          }
        }
      }
    }
  }
  
  .sky {
    .delete {
      border: 1px solid #fb3123;
      width: 14px;
      height: 14px;
      display: flex;
      justify-content: center;
      line-height: 11px;
      color: #fb3123;
      margin-left: 5px;
      letter-spacing: -1px;
      font-weight: 600;
    }
    
    .syj_betnum .syj_betnum-input {
      margin-left: 0;
    }
    
    display: block;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 350px;
    align-items: flex-start;
    margin: 0 auto 15px;
    background: #fdf4ed;
    padding: 18px 10px;
    min-height: 240px;
    overflow: auto;
    max-height: 255px;
    
    .syj_betnum {
      margin: 12px 0;
      width: 50%;
      float: left;
      justify-content: center;
    }
  }
  
  .syj_betnum {
    label {
      width: 60px;
    }
    
    display: inline-flex;
    color: #fff;
    margin: 4px 0;
    align-items: center;
    
    .syj_betnum-input {
      margin-left: 10px;
      display: flex;
    }
    
    span {
      &.numrandom {
        &:last-child {
          &::after {
            left: -15px;
          }
          
          margin-left: 16px;
        }
        
        width: 12px;
        height: 26px;
        margin: 0 3px;
        border: none;
        
        input {
          font-size: 22px;
          width: 12px;
        }
      }
      
      width: 28px;
      height: 33px;
      border: 2px solid #fb3123;
      display: block;
      margin: 0 5px;
      border-radius: 4px;
      
      &:last-child {
        margin-left: 29px;
        position: relative;
        
        &::after {
          position: absolute;
          width: 10px;
          height: 1px;
          background: #f44336;
          content: "";
          left: -22px;
          top: 50%;
        }
      }
      
      input {
        outline: none;
        text-align: center;
        width: 100%;
        height: 100%;
        padding: 0;
        background: #fff;
        border: none;
        color: #ef0606;
      }
    }
  }
  
  background: #363fb5;
  position: fixed;
  width: 500px;
  z-index: 10;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 25px;
  
  .btnselect {
    width: 138px;
    height: 35px;
    color: #fff;
    background: linear-gradient(#fb3123 13%, #fb3123 76%);
    border: none;
    border-radius: 25px;
    cursor: pointer;
    box-shadow: 1px 2px 0px #fb3123;
    line-height: 35px;
    margin-top: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 1px 3px 4px #770a03;
  }
  
  .syj_betcontentinput {
    display: none;
    
    &.actived {
      display: block;
    }
  }
  
  .syj_sky {
    label {
      background: url(https://maiyuan-zhusun.oss-cn-hangzhou.aliyuncs.com/upload/skytabdefault.png) no-repeat center center;
      width: 170px;
      height: 52px;
      line-height: 52px;
      color: #fff;
      font-weight: 600;
      cursor: pointer;
      
      &.actived {
        color: #fffc00;
      }
    }
    
    width: 370px;
    display: flex;
    justify-content: space-between;
    padding: 18px 0 12px;
    margin: auto;
  }
  
  .sky-input {
    display: flex;
    width: 370px;
    margin: auto;
    align-items: center;
    padding-bottom: 10px;
    
    input {
      outline: none;
      text-align: center;
      width: 110px;
      height: 36px;
      padding: 0;
      border: 2px solid #ef0606;
      color: #b2b2b2;
      margin-right: 10px;
      padding-left: 10px;
      background: #fff;
    }
    
    i {
      margin-right: 10px;
    }
    
    .btn {
      border-radius: 3px;
      background: #fb3123;
      line-height: 36px;
      padding: 0 12px;
      color: #fff;
      width: 78px;
      height: 36px;
      border: 2px solid #fff;
      font-weight: 600;
    }
  }
}

//支付请求loading  start
.bill_loading {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 9999;
}

.bill_loading-spinner {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 50px;
  height: 50px;
  margin-top: -25px;
  margin-left: -25px;
  border: 5px solid #f3f3f3;
  border-top: 5px solid #3498db;
  border-radius: 50%;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

//支付请求loading  end
</style>


<style lang="less" scoped>
@import '~@/style/common.less';

.wrap {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 20;
  width: 100vw;
  height: 100vh;
  overflow-y: auto;
}

.syj_Betinfo {
  position: relative;
  left: unset;
  top: unset;
  transform: unset;
  height: auto;
  padding-bottom: 40px;
  margin: 6% auto;
}

</style>
