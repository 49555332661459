<template>
  <div>
    <div class="syj_Qrcodeinfo-shadow"></div>
    <div class="syj_Qrcodeinfo">
      <div class="betClose">
        <img
            @click="IsClosebet"
            src="../assets/images/close.png"
        />
      </div>
      <div class="container">
        <div class="payment-code">
          <figcaption>扫码付款</figcaption>
          <div class="code" :class="{'on': nav === 1}">
            <div class="code-text"></div>
            <img v-bind:src="pay_url" alt="付款码图片描述">
          </div>
        </div>
        <div
            class="upload-payment-voucher"
            @mouseenter="handleNav(1)"
            @mouseleave="handleNav(0)"
        >
          <figcaption>扫码上传凭证</figcaption>
          <div class="code" :class="{'on': nav === 0}">
            <div class="code-text"></div>
            <img v-bind:src="upload_pay_url" alt="付款码图片描述">
          </div>
        </div>
      </div>
      <div class="tip_text_amount">需付款金额:{{ pay_amount }}元</div>
      <div class="tip_text">注:扫左侧二维码付款后保存付款截图,扫右侧二维码上传付款截图!</div>
      <div class="tip_text_blue" v-on:click="openModal">点击查看凭证示例</div>
      
      <div class="tip_text_black">
        <span style="font-weight: bold;">购票须知:</span>
        <span>1、本人自愿参加竞猜活动，同意并遵守活动规则。</span>
        <span>2、本奖券不挂失，不返还本金。</span>
        <span>3、购票者应当认真核对并确认奖券信息，本奖券一经售出，不予退换。</span>
        <span>4、中奖者应在开奖当日兑奖，逾期不兑奖视为弃奖，中奖奖券为兑奖唯一凭证。</span>
      </div>
    </div>
    <div v-if="showModal" class="modal" @click.self="closeModal">
      <span class="close" @click="closeModal">&times;</span>
      <img :src="imageUrl" alt="付款截图">
    </div>
  </div>
</template>
<script>


export default {
  data() {
    return {
      pay_url: '',
      upload_pay_url: '',
      pay_amount: '',
      imageUrl: '',
      showModal: false,
      nav: 0,
    }
  },
  props: ["parentQrcodeData"],
  methods: {
    openModal() {
      this.showModal = true;
    },
    closeModal() {
      this.showModal = false;
    },
    IsClosebet() {
      this.$confirm("确认下已付款且上传了付款凭证?", "", "", {
        customContainerClass: "containerClass",
        cancelButtonText: "稍后支付", confirmButtonText: "是滴",
        showLoaderOnConfirm: true,
        preConfirm: function () {
          //功能执行前确认操作，支持function
        },
        allowOutsideClick: () => !this.isLoading()
      }).then((result) => {
        console.log(result)
        this.$emit("IsCloseqrcodelist");
        this.$emit("IsClosebetp");
      }).catch(error => {
        console.log('error2', error)
        this.$emit("IsCloseqrcodelist");
        this.$emit("IsClosebetp");
      });
    },
    
    handleNav(e) {
      this.nav = e
    }
  },
  mounted() {
    this.pay_url = this.parentQrcodeData.data.pay_url
    this.upload_pay_url = this.parentQrcodeData.data.upload_pay_url
    this.pay_amount = this.parentQrcodeData.data.pay_amount
    this.imageUrl = this.parentQrcodeData.data.imageUrl
  }
}
</script>
<style lang="less">
.modal {
  display: block;
  position: fixed;
  z-index: 9999;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  text-align: center;
  padding: 20px;
}

.modal img {
  max-width: 90%;
  max-height: 90%;
  margin: 0 auto;
}

.close {
  color: #fff;
  font-size: 30px;
  font-weight: bold;
  position: absolute;
  top: 20px;
  right: 20px;
  cursor: pointer;
}

.tip_text_amount {
  color: red;
  font-size: 16px;
  font-weight: bold;
}

.tip_text {
  margin-top: 10px;
  color: red;
  font-size: 13px;
}

.tip_text_blue {
  //margin-top: 10px;
  color: #00c4ff;
  font-size: 13px;
  font-weight: bold;
  cursor: pointer;
}

.tip_text_black {
  margin-top: 10px;
  color: #0c0c0c;
  font-size: 12px;
}

.tip_text_black span {
  display: block;
  text-align: left;
}

.syj_Qrcodeinfo .container {
  display: flex;
  margin: 20px 0 20px;
  padding: 0 30px;
  width: 100%;
  box-sizing: border-box;
  overflow: hidden; /* 控制溢出部分隐藏 */
}

.syj_Qrcodeinfo .payment-code,
.syj_Qrcodeinfo .upload-payment-voucher {
  //width: 49%;
  //height: 86%;
}

.syj_Qrcodeinfo .container img {
  display: block;
  width: 100%;
  //height: 100%;
  object-fit: contain;
  object-position: center center;
}

/*.container {
  display: flex;
  flex-wrap: wrap; !* 允许容器中的元素换行 *!
}

.payment-code, .upload-payment-voucher {
  margin-top: 10px;
  margin-right: 10px; !* 根据需要调整间距 *!
}
.payment-code, .upload-payment-voucher img {
  width: 50%; !* 设置图片宽度为容器宽度的一半 *!
  height: 50%; !* 保持图片高度自适应 *!
  object-fit: contain; !* 限制图片宽高并在各自容器内居中显示 *!
}
.upload-payment-voucher img {
  width: 100%; !* 设置图片宽度为容器宽度的一半 *!
}*/

.syj_Qrcodeinfo-shadow {
  z-index: 10;
  position: fixed;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  left: 0;
}

.syj_Qrcodeinfo {
  .notice {
    color: #fff;
    text-align: left;
    width: 73%;
    margin-top: 12px;
    line-height: 12px;
    font-size: 14px;
  }
  
  .icon-jiao {
    position: relative;
    width: 20px;
    height: 20px;
    display: inline-block;
    
    &::after {
      position: absolute;
      width: 8px;
      height: 1px;
      content: "";
      background: #ffffff;
      left: 10px;
      bottom: 6px;
      z-index: 1;
      transform: rotate(-45deg);
    }
    
    &::before {
      position: absolute;
      width: 8px;
      height: 1px;
      content: "";
      background: #ffffff;
      left: 10px;
      top: 8px;
      z-index: 1;
      transform: rotate(45deg);
    }
  }
  
  .syj_contentselect {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 73%;
    color: #fb3123;
    font-weight: 600;
    font-size: 14px;
    letter-spacing: 1px;
    margin-top: 12px;
    
    .selectBox {
      border: 2px solid #fb3123;
      
      .selectBox_show {
        text-align: left;
        cursor: pointer;
        position: relative;
        
        p {
          color: red;
          font-size: 12px;
          padding: 10px 0;
          padding-left: 10px;
          margin: 0;
        }
        
        i {
          position: absolute;
          top: 15px;
          right: 10px;
          width: 0;
          height: 0;
          border-width: 8px 6px 0;
          border-style: solid;
          border-color: #fb3123 transparent;
          border-radius: 10px;
          display: inline-block;
        }
      }
    }
    
    .selectBox_list {
      height: 120px;
      color: #2e2e2e;
      margin-bottom: 5px;
      text-align: left;
      overflow: auto;
      font-size: 12px;
      padding-left: 10px;
      line-height: 22px;
      background-color: #ffffff;
      position: absolute;
      width: 268px;
      border: 2px solid #f44336;
      margin-left: -2px;
      border-top: none;
      
      .selectBox_listLi {
        cursor: pointer;
      }
      
      .selectBox_listLi:hover {
        color: #f44336;
      }
    }
  }
  
  .betClose img {
    transform: scale(0.7);
  }
  
  .betClose {
    width: 96%;
    height: 7%;
    cursor: pointer;
    text-align: right;
    margin: 10px 8px 3px;
  }
  
  .syj_Betinfo-tab {
    width: 370px;
    display: flex;
    justify-content: space-around;
    padding: 5px 0 10px;
    color: #fff;
    
    .tabbet {
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      
      label {
        position: relative;
        width: 15px;
        height: 15px;
        border: 2px solid #e81f3c;
        display: block;
        border-radius: 50%;
        margin-right: 10px;
      }
      
      &.actived {
        label {
          span {
            width: 10px;
            height: 10px;
            background: #e81f3c;
            display: inline-block;
            border-radius: 50%;
            position: absolute;
            top: 49%;
            left: 50%;
            transform: translate(-50%, -50%);
          }
        }
      }
    }
  }
  
  .sky {
    .delete {
      border: 1px solid #fb3123;
      width: 14px;
      height: 14px;
      display: flex;
      justify-content: center;
      line-height: 11px;
      color: #fb3123;
      margin-left: 5px;
      letter-spacing: -1px;
      font-weight: 600;
    }
    
    .syj_betnum .syj_betnum-input {
      margin-left: 0;
    }
    
    display: block;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 350px;
    align-items: flex-start;
    margin: 0 auto 15px;
    background: #fdf4ed;
    padding: 18px 10px;
    min-height: 240px;
    overflow: auto;
    max-height: 255px;
    
    .syj_betnum {
      margin: 12px 0;
      width: 50%;
      float: left;
      justify-content: center;
    }
  }
  
  .syj_betnum {
    label {
      width: 60px;
    }
    
    display: inline-flex;
    color: #0f1630;
    margin: 4px 0;
    align-items: center;
    
    .syj_betnum-input {
      margin-left: 10px;
      display: flex;
    }
    
    span {
      &.numrandom {
        &:last-child {
          &::after {
            left: -15px;
          }
          
          margin-left: 16px;
        }
        
        width: 12px;
        height: 26px;
        margin: 0 3px;
        border: none;
        
        input {
          font-size: 22px;
          width: 12px;
        }
      }
      
      width: 28px;
      height: 33px;
      border: 2px solid #fb3123;
      display: block;
      margin: 0 5px;
      border-radius: 4px;
      
      &:last-child {
        margin-left: 29px;
        position: relative;
        
        &::after {
          position: absolute;
          width: 10px;
          height: 1px;
          background: #f44336;
          content: "";
          left: -22px;
          top: 50%;
        }
      }
      
      input {
        outline: none;
        text-align: center;
        width: 24px;
        height: 29px;
        padding: 0;
        background: transparent;
        border: none;
        color: #ef0606;
      }
    }
  }
  
  background: #fff;
  position: fixed;
  width: 550px;
  z-index: 10;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 25px;
  padding-bottom: 30px;
  
  .btnselect {
    width: 138px;
    height: 35px;
    color: #fff;
    background: linear-gradient(#fb3123 13%, #fb3123 76%);
    border: none;
    border-radius: 25px;
    cursor: pointer;
    box-shadow: 1px 2px 0px #fb3123;
    line-height: 35px;
    margin-top: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .syj_betcontentinput {
    display: none;
    
    &.actived {
      display: block;
    }
  }
  
  .syj_sky {
    label {
      background: url(https://maiyuan-zhusun.oss-cn-hangzhou.aliyuncs.com/upload/skytabdefault.png) no-repeat center center;
      width: 166px;
      height: 55px;
      line-height: 55px;
      color: #fff;
      font-weight: 600;
      cursor: pointer;
      
      &.actived {
        background: url(../assets/images/skyhover.png) no-repeat center center;
      }
    }
    
    width: 370px;
    display: flex;
    justify-content: space-between;
    padding: 18px 0 12px;
    margin: auto;
  }
  
  .sky-input {
    display: flex;
    width: 370px;
    margin: auto;
    align-items: center;
    padding-bottom: 10px;
    
    input {
      outline: none;
      text-align: left;
      width: 100px;
      height: 36px;
      padding: 0;
      background: transparent;
      border: 2px solid #ef0606;
      color: #ef0606;
      margin-right: 10px;
      padding-left: 10px;
    }
    
    i {
      margin-right: 10px;
    }
    
    .btn {
      border-radius: 3px;
      background: #fb3123;
      line-height: 36px;
      padding: 0 12px;
      color: #fff;
      width: 78px;
      height: 40px;
    }
  }
}

//@media (max-width: 1440px) {
//  .syj_Qrcodeinfo {
//    height: 450px;
//    .betClose {
//      margin-top: 5px;
//    }
//    .btnselect {
//      margin-top: 5px;
//    }
//  }
//}
</style>

<style scoped lang="less">
.syj_Qrcodeinfo {
  .container {
    .payment-code {
      display: flex;
      flex-direction: column;
      flex: 1;
      flex-shrink: 0;
      gap: 20px;
      
      .code {
        position: relative;
        overflow: hidden;
        flex: 1;
        transition: all 1s;
        &:before {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          z-index: 1;
          width: 100%;
          height: 100%;
          background-color: #fff;
          opacity: 0;
          transition: all 300ms;
        }
        
        
        &.on {
          &:before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            z-index: 1;
            width: 100%;
            height: 100%;
            background-color: #fff;
            opacity: 0.8;
            backdrop-filter: blur(5px);
          }
          
          .code-text {
            opacity: 1;
          }
          
          img {
            transform: scale(0.8);
            filter: blur(5px);
          }
        }
        
        .code-text {
          position: absolute;
          top: 0;
          left: 0;
          z-index: 2;
          width: 100%;
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          font-weight: 700;
          color: #fe0301;
          opacity: 0;
          transition: all 300ms;
        }
        img {
          transform: scale(1);
          transition: all 300ms;
        }
      }
    }
    
    .upload-payment-voucher {
      display: flex;
      flex-direction: column;
      flex: 1;
      flex-shrink: 0;
      gap: 20px;
      
      .code {
        position: relative;
        overflow: hidden;
        flex: 1;
        transition: all 1s;
        &:before {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          z-index: 1;
          width: 100%;
          height: 100%;
          background-color: #fff;
          opacity: 0;
          transition: all 300ms;
        }
        
        
        &.on {
          &:before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            z-index: 1;
            width: 100%;
            height: 100%;
            background-color: #fff;
            opacity: 0.8;
            backdrop-filter: blur(5px);
          }
          
          .code-text {
            opacity: 1;
          }
          
          img {
            transform: scale(0.8);
            filter: blur(5px);
          }
        }
        
        .code-text {
          position: absolute;
          top: 0;
          left: 0;
          z-index: 2;
          width: 100%;
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          font-weight: 700;
          color: #fe0301;
          opacity: 0;
          transition: all 300ms;
        }
        img {
          transform: scale(1);
          transition: all 300ms;
        }
      }
    }
  }
}
</style>
